import React from "react"
import { Box, Center, Flex, Heading, Icon } from "@chakra-ui/react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
const Footer = ({ location }) => {
  return (
    <Box minH={60} bgColor="#333">
      <Flex
        maxW="1240px"
        margin="0 auto"
        color="#fff"
        justifyContent="space-between"
        px={8}
        pt={9}
      >
        {/* left  */}
        <Box>
          <StaticImage
            src={"../images/MindfulnessCapital_newfooter.png"}
            // src={"../images/MindfulnessCapital_footer.png"}
            alt="MindfulnessCapitalfooter"
            placeholder="blurred"
            // width={140}
            // height={27}
          />
          <Box pt={2} style={{ paddingTop: "0px" }}>
            {location.pathname === "/"
              ? "Contact Us"
              : location.pathname === "/simplifiedChinese"
                ? "联系我们"
                : "聯繫我們"}
          </Box>
          <Box fontSize={{ base: "10px" }}>102A-1456 11TH Ave, Regina, SK S4P 0G9</Box>
          <Box fontSize={{ base: "10px" }}>   {location.pathname === "/"
              ? "Tel"
              : location.pathname === "/simplifiedChinese"
                ? "电话"
                : "電話"}: （306)351-9318</Box>
        </Box>

        <Box w={{ base: 10, sm: 6, md: 6 }} />
        {/* right  */}

        {/* <Box mr={{ sm: 0, md: 28 }}>
          <Box fontSize="sm">
            {location.pathname === "/"
              ? "Social Media"
              : location.pathname === "/simplifiedChinese"
                ? "社交媒体"
                : "社交媒體"}
          </Box>
          <Flex mt={5}>
            <Box
              cursor="pointer"
              w={{ base: "35px", sm: "35px", lg: "35px" }}
              onClick={() => {
                navigate("https://twitter.com/MindfulnessCap")
              }}
            >
              <StaticImage
                src={"../images/twitter.png"}
                alt="twitter"
                placeholder="blurred"
              />
            </Box>

            <Box w={{ base: 6, sm: 6, md: 6 }} />
            <Box
              cursor="pointer"
              w={{ base: "35px", sm: "35px", lg: "35px" }}
              onClick={() => {
                navigate("https://www.linkedin.com/company/72362997/")
              }}
            >
              <StaticImage
                src={"../images/linkedin.png"}
                alt="linkedin"
                placeholder="blurred"
              />
            </Box>
          </Flex>
        </Box> */}

        
      </Flex>

      <Center mt={7} color="#fff">
        @YQX Building Renovation & Home Furnishing Limited All rights reserved
      </Center>
    </Box>
  )
}

export default Footer
