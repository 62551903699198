import React from "react"
import { Link, Box, Flex, Heading } from "@chakra-ui/react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ location }) => {
  return (
    // pc
    <Flex
      display={{ base: "none", sm: "none", md: "flex" }}
      justifyContent="space-between"
      alignItems="center"
      w={{ md: "46rem", lg: "71rem", xl: "71rem" }}
      margin="0 auto"
      maxW="1100px"
    >
      <Flex alignItems="center">
        <Box w="4rem" h="4rem">
          <StaticImage
            src={"../images/gatsby-icon.png"}
            alt="headerMindfulnessCapital"
            placeholder="blurred"
          />
        </Box>
        <Box as="span" fontSize="1.5rem" fontWeight="900">
          YQX Building Renovation & Home Furnishing
        </Box>
      </Flex>

      <Flex h="16" alignItems="center">
        <Flex
          mr={{ md: "9", lg: "20" }}
          color="#333333"
          fontWeight="500"
          cursor="pointer"
          borderBottom="4px solid #333"
          h="16"
          alignItems="center"
        >
          {location.pathname === "/"
            ? "Home"
            : location.pathname === "/simplifiedChinese"
            ? "首页"
            : "首頁"}
        </Flex>
        <Flex
          mr={{ md: "9", lg: "20" }}
          fontWeight="500"
          color="#999"
          cursor="pointer"
          alignItems="center"
        >
          <AnchorLink href="#About">
            {" "}
            {location.pathname === "/"
              ? "Our Services"
              : location.pathname === "/simplifiedChinese"
              ? "会员介绍"
              : "會員介紹 "}
          </AnchorLink>
        </Flex>
        <Flex
          mr={{ md: "9", lg: "20" }}
          fontWeight="500"
          color="#999"
          cursor="pointer"
          alignItems="center"
        >
          <a href="mailto:chao.li@usask.ca">Email Us</a>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Header
